div.messageContainer {
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 6px; 
    margin-bottom: 6px; 
    flex-direction: row;
    display: flex;
}
div.bubble {
    padding-top: 7px; 
    padding-bottom: 7px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 18px; 
    display: flex;
    flex-direction: row; 
    align-items: stretch;
    flex-wrap: wrap;
    overflow: hidden;
}
div.bubbleSize {
    max-width: 65%; 
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
p.messageText { 
    line-height: 22px; 
    font-weight: 400;
}
div.bubbleTime {
    align-self: stretch;
    flex-grow: 1; 
    align-items: flex-end;
    justify-content: flex-end;
}
p.bubbleTimeText {
    position: relative;
    line-height: 18px;
    top: 2px;
    font-size: 9px;
    padding-left: 8px;
    font-weight: 400;
}
div.input{
    width: 80%;
    background-color: yellow;
}
div.inputBar{
    align-self: flex-start;
}
