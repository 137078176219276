.container24 {
    position: relative;
    display: flex;
    flex-direction: row;  
    background-color: #f1f2f5; 
    height: calc(100VH - 92.83px);
}
.chatScreenContainer {
    background-color: #fff; 
    display: flex;
    width: 70%;
    height: calc(100VH - 92.83px); 
    
}
.chatFilterWrapper{
    height: 49px;
    display: flex;
    width: 100%;
    flex-direction: row;    
    align-items: center; 
    justify-content: center; 
    background-color: #fff;
    border-right: 1px solid #f1f2f5;
    padding: 0 12px 0 12px;
}
.chatListContainer{
    overflow-y: scroll;
    height: calc(100% - 108px); 
    background-color: #fff;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #f8f8f8; 
    border-right: 1px solid #f1f2f5;
}
.filterForm{
    width: 100%;
}
.filterInput{
    background-color: #f1f2f5;
    border: 0;
    border-radius: 10px;
    height: 35px;
    width: 100%;
    padding: 0 12px 0 12px;

}
.filterInput:focus{
    border: 0;
}
input:focus { 
    outline: none !important;
    
}
.chatHeaderTitleWrapper{
    background-color: #f1f2f5;
    height: 59px;
    border-right: 1px solid rgb(209, 209, 209);
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px 0 12px;
}
.supportChatHeader{
    font-weight: 800;
}
.supportLink{
    font-size: 10px;
    color: inherit;
}
.magnifyIcon{
    font-size: large;
    padding-right: 12px;
}