.chatSelect{
    height: 73px;
    width: 100%;
    background-color: white;
    border: 0;
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0px 0px 0px 0px;

}
.chatSelect:focus{
    background-color: #f5f6f6;
}
.chatSelect:hover{
    background-color: #f5f6f6;
}
.textWrapper{
    display: flex;
    border-bottom: 1px;
    border-bottom-style: solid;
    border-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 5px 0px 5px 0px;
    width: calc(100% - 77px);
}
.profileImageWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 77px;
    height: 73px;
}
.profileImage{
    height: 49px;
    width: 49px;
    border: 0;
    border-radius: 25px;
    background-color: #f8f8f8;

}
.textLineWrapper{
    display: flex;
    flex-direction: row;
    padding-right: 15px;
}
.textLineWrapper3{
    display: flex;
    flex-shrink: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 15px;
    overflow: hidden;
    flex-grow: 0;
    width: 100%;
    
}


.container {
    position: 'relative';
    background-color: 'white';
    border-radius: 20;
    padding-left: 10;
    padding-right: 10;
    padding-top: 20;
    padding-bottom: 20;
    height: 200;
    width: 150;
    margin-right: 20
    
}
.notificationContainer {
    position: 'absolute';
    top: 0;
    left: 0;
    background-color: 'red';
    width: 10;
    height: 10;
    border-radius: 10;
}
.lastMessageTime {
    font-size: 10;
}
.notificationText {
    font-size: 12;
}

.shipmentNo {
    font-size: 16;
    font-weight: 'bold'
}
.supportTitle {
    font-size: 10;
    font-weight: 'bold';
}
.supportTopic {
    font-size: 12;
}
.lastMessage {
    color: #969696;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 10px;
}
.lastUpdate{
    align-self: "flex-end";
    font-size: 10;
}
.nameLine{
    font-weight: 800;
}