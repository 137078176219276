.enterMessageForm{
    position: relative;
    background-color: #f1f2f5;
    width: 100%;
    display: flex;
    font-size: 1.5rem;
    height: 62px;
    align-items: center;
    justify-content: center;
  }
.chatHeader{
    background-color: #f1f2f5;
    height: 59px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.chatWrapper{
    
    background-color: #fff;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    height: calc(100VH - 213.83px);
  }

.messageInput{
    width: 70%;
    margin-right: 3%;
    background-color: white;
    height: 42px;
    border-radius: 10px;
    border: 0;
    padding: 5px 5px 5px 5px;
}

.sendButton{
    
    border: 0;
    background-color: #f1f2f5;
    color: gray;
}

.sendIcon {
    font-size: 36px;
}
.clientWrapper{
    display: flex;
    flex-direction: row;
    padding: 0 12px 0 12px;
    align-items: center;
}
.clientContainer{
    display: flex;
    flex-direction: column;
    padding-left: 12px;
}
.closeButton{
    background-color: #d72323;
    padding: 12px 12px 12px 12px;
    border: 0;
    border-radius: 8px;
    margin-right: 12px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}